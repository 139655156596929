import React from "react";

export default function Spinner() {
    return (
        <div className="containerSpinnerCenter">
            <div className="spinnerBlue">
            </div>
            <h2>Cargando...</h2>
        </div>
    )
}